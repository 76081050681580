import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ProfileComponent } from './components/profile/profile.component';
import { BookingComponent } from './components/booking/booking.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { MyphysioComponent } from './components/myphisio/myphysio.component';
import { RoutinesComponent } from './components/routines/routines.component';
import { ReportClientsComponent } from './components/reports-clients/reportClients.component';
import { MypatientsComponent } from './components/mypatients/mypatients.component';
import { TestcsvComponent } from './components/test-csv/testcsv.component';
import { EditroutineComponent } from './components/edit-routine/editroutine.component';
import { ReportComponent } from './components/report/report.component';

const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'sign-up', component: RegisterComponent },
    { path: 'messages', component: MessagesComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'booking', component: BookingComponent },
    { path: 'timeline', component: TimelineComponent },
    { path: 'my-physio', component: MyphysioComponent },
    { path: 'routines', component: RoutinesComponent },
    { path: 'reportClients', component: ReportClientsComponent },
    { path: 'my-patients', component: MypatientsComponent },
    { path: 'test-csv', component: TestcsvComponent },
    { path: 'edit-routine', component: EditroutineComponent },
    { path: 'report/:reporte', component: ReportComponent }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);

