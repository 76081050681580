import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import { AssignedService } from '../../services/assigned.service';
import { TestcsvComponent } from '../test-csv/testcsv.component';
import { ReportService } from '../../services/report.service';

@Component({
    selector: 'app-reportsclients',
    styleUrls: ['./reportClients.component.css'],
    templateUrl: './reportClients.component.html',
    providers: [UserService, AssignedService, ReportService]
})

export class ReportClientsComponent implements OnInit {
    public title: string;
    public identity: any;
    public avatarUrl: any;
    public trustedUrl: any;
    public patientsArray: Array<any>;
    public countPatient: number;
    public prePatient: Array<any>;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _reportService: ReportService, private _assignService: AssignedService, private _sanitizer: DomSanitizer) {
        this.title = 'Reports Clients';
        this.countPatient = 0;
    }

    ngOnInit() {
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);

        this._assignService.getAssignPatients(localStorage.getItem('token')).subscribe(res => {
            this.patientsArray = [];
            this.prePatient = [];
            this.countPatient++;
            if (this.countPatient <= 2) {
                res.patients.forEach(element => {
                    if (element.patient && this.countPatient <= 2) {
                        this._reportService.getAllReportsById(localStorage.getItem('token'), element.patient._id).subscribe(resolve => {
                            console.log(resolve);
                            resolve['reports'].forEach(re => {
                                re.uploadedAt = moment(moment.unix(re.uploadedAt)).format('L');
                            });
                            this.prePatient.push({ pat: element.patient, reports: resolve });
                        },
                            err => {
                                console.log(err);
                            });

                    } else {
                        this.patientsArray.push(this.prePatient);
                    }
                });
                console.log(this.prePatient);
            } else {
                this.prePatient = [];
                this.countPatient = 0;
            }
            console.log('Aqui', this.patientsArray);
        },
            err => {
                console.log(err);
            });
    }

    newReport(idPatient) {
        this._router.navigate(['/test-csv', { pat: idPatient }]);
    }

    openReports(id: any): void {
        $('#' + id).children('#reports-open').toggle(function () {
            $(this).removeClass('inactive');
        });
    }
}
