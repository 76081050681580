import { Component, OnInit, DoCheck } from "@angular/core";
import { UserService } from "./services/user.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { GLOBAL } from "./services/global";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MessagesService } from "./services/messages.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [UserService, MessagesService]
})
export class AppComponent implements OnInit, DoCheck {
  stats;
  trustedUrl: SafeUrl;
  public avatarUrl: any;
  public title: String;
  public identity;
  public url;
  public numMess;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _sanitizer: DomSanitizer,
    private _messageService: MessagesService
  ) {
    this.title = "AnamoLOCKER";
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.identity = this._userService.getIdentity();
    this.avatarUrl = this._userService.getImageAvatar();
    this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);
    this.numMess = localStorage.getItem("messageCount");
    console.log(this.trustedUrl);
  }

  ngDoCheck() {
    this.identity = this._userService.getIdentity();
    this.avatarUrl = this._userService.getImageAvatar();
    this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);
    this.numMess = localStorage.getItem("messageCount");
  }

  logout() {
    localStorage.clear();
    this.identity = null;
    this._router.navigate(["/"]);
  }
}
