import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, observable } from "rxjs";
import { User } from "../models/user";
import { GLOBAL } from "./global";
import { UserLogin } from "../models/userLogin";

@Injectable()
export class ReportService {
  public url: String;

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getReport(report) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", localStorage.getItem("token"));

    return this._http.get(this.url + "get-report/" + report, {
      headers: headers
    });
  }
  getAllReports(token, page = null): Observable<any> {
    if (page === null) {
      page = 1;
    }

    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", token);

    return this._http.get(this.url + "get-all-reports/" + page, {
      headers: headers
    });
  }
  getAllReportsById(token, patID = null) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", token);

    return this._http.get(this.url + "get-reports-user/" + patID, {
      headers: headers
    });
  }
}
