import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { GLOBAL } from './global';
import { User } from '../models/user';

@Injectable()
export class AssignedService {
    public url: String;


    constructor(private _http: HttpClient) {
        this.url = GLOBAL.url;
    }

    assignPatient(pat: string, token: any): Observable<any> {
        let params = pat;
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

        return this._http.post(this.url + 'assign-patient', { patient: params }, { headers: headers });
    }

    getAssignPhysio(token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', token);

        return this._http.get(this.url + 'get-physio/', { headers: headers });
    }
    getAssignPatients(token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', token);

        return this._http.get(this.url + 'get-patients/', { headers: headers });
    }
}
