import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { ReportService } from '../../services/report.service';

@Component({
    selector: 'app-timeline',
    styleUrls: ['./timeline.component.css'],
    templateUrl: './timeline.component.html',
    providers: [UserService, ReportService]
})

export class TimelineComponent implements OnInit {
    public title: string;
    public identity: any;
    public avatarUrl: any;
    public trustedUrl: any;
    public reportsArray: Array<any>;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _sanitizer: DomSanitizer, private _reportService: ReportService) {
        this.title = 'Timeline';
    }

    ngOnInit() {
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);

        this._reportService.getAllReports(localStorage.getItem('token')).subscribe(res => {
            this.reportsArray = [];
            console.log(res);
            res.reports.forEach(rep => {
                rep.uploadedAt = moment(moment.unix(rep.uploadedAt)).format('LLLL');
                this.reportsArray.push(rep);
            });
        },
            err => {
                console.log(err);
            });
    }



}
