import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service'; 
import { ReportService} from '../../services/report.service';

@Component({
    selector: 'app-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html',
    providers: [UserService, ReportService]
})

export class HomeComponent implements OnInit {
    public report: any;
    public stats: any;
    public title: String;
    public token: any;
    public emptyMessage: string;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _reportService: ReportService) {
        this.title = 'Home';
    }

    ngOnInit() {
        this.stats = this._userService.getStats();
        this.token = localStorage.getItem('token');
        this._reportService.getAllReports(this.token).subscribe( resAllReports => {
            if (resAllReports.total !== 0) {
                this._reportService.getReport(resAllReports.reports[0].reportName).subscribe( response => {
                    var file = new Blob([response], {type: 'text/plain'});
                    var fileURL = URL.createObjectURL(file);
        
                    console.log(file);
                    console.log(fileURL);
                },
                err => {
                    console.log(err);
                });
            } else {
                this.emptyMessage = 'You do not have reports at this moment';
            }
        },
        err => {
            console.log(err);
        });
    }

}
