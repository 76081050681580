import { Component } from "@angular/core";
import { UploadService } from "../../services/upload.service";
import { GLOBAL } from "../../services/global";
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: "app-testcsv",
  templateUrl: "./testcsv.component.html",
  styleUrls: ["./testcsv.component.css"],
  providers: [UploadService]
})
export class TestcsvComponent {
  public file: any;
  public title = "app";
  public dataArray: Array<any>;
  public dataCSV: string;
  public condName: Array<any>;
  public names: Array<string>;
  public auxValues: Array<any>;
  public values: Array<any>;
  public cont: number;
  public donArray: Array<any>;
  public nom: string;
  public hslArray: Array<any>;
  public hsrArray: Array<any>;
  public squatArray: Array<any>;
  public stado: string;
  public allpoints: number;
  public percent: Array<any>;
  public graphs: Array<any>;
  public graphLines: any;
  public stacked: number;
  public graphLinMark: any;
  public xAxe: Array<any>;
  public auxAxe: any;
  public seeBrow: any;
  public puntos: Array<any>;
  public otroLine: any;
  public bothLine: Array<any>;

  //------------test values-------------
  public firstFrame: any;
  public secondFrame: any;
  public maxTotoal: any;
  public seeDouble: boolean;
  public seeSingle: boolean;
  public seeButtons: boolean;

  public csvUpload: Array<File>;
  public url: any;
  public token: string;
  public inputText: string;

  constructor(private _route: ActivatedRoute, private _router: Router, private _uploadService: UploadService) {
    this.condName = [];
    this.cont = 0;
    this.stado = "CLOSE";
    this.url = GLOBAL.url;
    this.inputText = 'Browser files';
  }

  uploadCSV(): void {

    this.token = localStorage.getItem('token');
    console.log(this.csvUpload);
    // tslint:disable-next-line:max-line-length
    const ident = JSON.parse(JSON.stringify(localStorage.getItem('user')));
    // tslint:disable-next-line:max-line-length
    this._uploadService.makeFileRequest(this.url + 'upload-report/' + this._route.snapshot.paramMap.get('pat'), [], this.csvUpload, this.token, 'csv').then((resCSV: any) => {
      console.log(resCSV);
    });

  }

  fileChanged(e) {
    this.csvUpload = <Array<File>>e.target.files;
    this.inputText = this.csvUpload[0].name;
    this.seeDouble = false;
    this.seeSingle = true;
    this.file = e.target.files[0];
    this.seeBrow = true;
  }

  browserSee(hsl, hsr) {
    console.log(hsl.value, " --- ", hsr.value);
    if (hsl.value !== "" && hsr.value !== "") {
      this.seeButtons = true;
      this.firstFrame = hsl.value;
      this.secondFrame = hsr.value;
      this.uploadDocument();
    } else {
      console.log("entra aqui");
    }
  }

  uploadDocument() {
    this.seeDouble = false;
    this.seeSingle = true;
    console.log("analisis");
    let fileReader = new FileReader();
    this.donArray = [];
    this.names = [];
    fileReader.onload = e => {
      this.dataCSV = fileReader.result;
      this.dataArray = this.dataCSV.split("\n");
      this.dataArray.forEach(elArray => {
        this.condName = elArray.split(",");
        if (this.condName[0] === "name:") {
          this.nom = this.condName[1];
          this.names.push(this.condName[1]);
          this.auxValues = [];
          this.graphs = [];
          this.values = [];
          this.hslArray = [];
          this.hsrArray = [];
          this.squatArray = [];
          this.percent = [];
          this.stacked = 0;
        } else if (this.condName[0] === "raw values:") {
          this.condName.shift();
          this.condName.forEach(dat => {
            this.auxValues.push(dat);
          });
          // PRUEBA --------------------------------------------------
          this.allpoints = this.auxValues.length;
          console.log(this.firstFrame, "--", this.secondFrame);
          for (let i = this.firstFrame - 1; i < this.secondFrame - 1; i++) {
            this.values.push(this.auxValues[i]);
          }

          for (let t = 0; t < this.values.length; t++) {
            this.percent.push((t / this.values.length) * 100);
          }
          this.auxAxe = 0;
          this.xAxe = [];
          let c = 0;

          if (this.secondFrame > this.auxValues.length - 1) {
            this.maxTotoal = this.auxValues.length - 1;
          } else {
            this.graphLines = {
              data: [
                {
                  x: this.percent,
                  y: this.values,
                  type: "scatter",
                  mode: "lines",
                  marker: { color: "red" }
                }
                //{ x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
              ],
              layout: { title: this.nom }
            };
            this.graphLinMark = {
              data: [
                {
                  x: this.percent,
                  y: this.values,
                  type: "scatter",
                  mode: "lines+markers",
                  marker: { color: "blue" }
                }
                //{ x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
              ],
              layout: { title: this.nom }
            };
            // tslint:disable-next-line:max-line-length
            this.donArray.push({
              title: this.nom,
              valores: this.values,
              hsl: this.firstFrame,
              hsr: this.secondFrame,
              //squat: this.squatArray,
              graphLine: this.graphLines,
              graphLinMark: this.graphLinMark
            });

            // PRUEBA END -----------------------------------------
            this.cont = 0;
            // }
          }
        }
      });
    };
    fileReader.readAsText(this.file);
  }

  otro() {
    this.seeDouble = true;
    this.seeSingle = false;
    this.bothLine = [];
    for (let c = 0; c < this.donArray.length; c++) {
      if (c % 2 !== 0) {
        console.log(this.donArray[c], "---", this.donArray[c + 1]);
        this.otroLine = {
          data: [
            {
              x: this.donArray[c - 1]["graphLine"]["data"][0]["x"],
              y: this.donArray[c - 1]["graphLine"]["data"][0]["y"],
              type: this.donArray[c - 1]["graphLine"]["data"][0]["type"],
              mode: this.donArray[c - 1]["graphLine"]["data"][0]["mode"],
              marker: {
                color: "red"
              }
            },
            {
              x: this.donArray[c]["graphLine"]["data"][0]["x"],
              y: this.donArray[c]["graphLine"]["data"][0]["y"],
              type: this.donArray[c]["graphLine"]["data"][0]["type"],
              mode: this.donArray[c]["graphLine"]["data"][0]["mode"],
              marker: {
                color: "blue"
              }
            }
          ],
          layout: { title: this.donArray[c]["title"] }
        };
        this.bothLine.push(this.otroLine);
      }
    }
  }

  changeStado() {
    if (this.stado === "CLOSE") {
      this.stado = "OPEN";
    } else {
      this.stado = "CLOSE";
    }
  }
}
