export class User {
    constructor(
        public _id: String,
        public name: String,
        public surname: String,
        public nickname: String,
        public companyID: String,
        public genre: String,
        public numID: String,
        public nationality: String,
        public physioLicense: String,
        public speciality: String,
        public fax: String,
        public assurance: String,
        public website: String,
        public birthdate: String,
        public email: String,
        public phone: String,
        public address: String,
        public description: String,
        public password: String,
        public role: String,
        public image: String,
        public enrollat: String
    ) { }
}