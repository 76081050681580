import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { User } from '../models/user';
import { GLOBAL } from './global';
import { UserLogin } from '../models/userLogin';

@Injectable()
export class UserService {
    stats: any;
    public url: String;
    public identity: any;
    public token: any;
    public imagen: any;

    constructor(private _http: HttpClient) {
        this.url = GLOBAL.url;
    }

    register(user: User): Observable<any> {
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url + 'register', params, { headers: headers });
    }

    login(user: UserLogin, gettoken = null): Observable<any> {
        if (gettoken != null) {
            user.gettoken = gettoken;
        }
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url + 'login', params, { headers: headers });
    }

    getIdentity() {
        let identity = JSON.parse(localStorage.getItem('user'));

        if (identity !== 'undefined') {
            this.identity = identity;
        } else {
            this.identity = null;
        }

        return this.identity;
    }

    getToken() {
        let token = JSON.parse(localStorage.getItem('token'));

        if (token !== 'undefined') {
            this.token = token;
        } else {
            this.token = null;
        }

        return this.token;
    }

    getStats() {
        let stats = JSON.parse(localStorage.getItem('stats'));

        if (stats !== undefined) {
            this.stats = stats;
        } else {
            this.stats = null;
        }
        return stats;
    }

    getCounter(token: any, userId = null): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token).set('Content-type', 'application/json');
        if (userId != null) {
            return this._http.get(this.url + 'count-report/' + userId, { headers: headers });
        } else {
            return this._http.get(this.url + 'count-report/', { headers: headers });
        }
    }

    getAvatar(token, imageId): Observable<Blob> {
        console.log(token + '----' + imageId);
        let headers = new HttpHeaders().set('Authorization', token).set('Content-type', 'application/json');

        return this._http.get(this.url + 'profile-image/' + imageId, { headers: headers, responseType: 'blob' });

    }

    getImageAvatar() {
        let imagen = localStorage.getItem('avatar');

        if (imagen !== 'undefined') {
            this.imagen = imagen;
        } else {
            this.imagen = null;
        }
        console.log(this.imagen);
        return this.imagen;
    }
}
