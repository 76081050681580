import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import { AssignedService } from '../../services/assigned.service';

@Component({
    selector: 'app-myphysio',
    styleUrls: ['./myphysio.component.css'],
    templateUrl: './myphysio.component.html',
    providers: [UserService, AssignedService]
})

export class MyphysioComponent implements OnInit {
    public title: string;
    public identity: any;
    public avatarUrl: any;
    public trustedUrl: any;
    public physioData: Array<any>;
    public token: string;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _assignedService: AssignedService, private _sanitizer: DomSanitizer) {
        this.title = 'My physio';
    }

    ngOnInit() {
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);

        this.token = localStorage.getItem('token');

        this.getPhysio(this.token);
    }

    getPhysio(token) {
        this._assignedService.getAssignPhysio(token).subscribe( response =>  {
            console.log(response.myPhysio[0].physio);
            this.physioData = response.myPhysio[0].physio;
        },
        err => {
            console.log(err);
        });
    }
}
