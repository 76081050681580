import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessagesService } from '../../services/messages.service';
import { Messages } from '../../models/messages';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { UserService } from '../../services/user.service';
import { UploadService } from '../../services/upload.service';
import { AssignedService } from '../../services/assigned.service';
import { GLOBAL } from '../../services/global';
import * as $ from 'jquery';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css'],
    providers: [MessagesService, UserService, UploadService, AssignedService]
})
export class MessagesComponent implements OnInit, DoCheck {
    identity: any;
    public day: any;
    public fecha: Array<any>;
    public today: any;
    public sended: number;
    public title: string;
    public messages: Messages;
    public message: Messages;
    public listMessages: Array<Messages>;
    public resultado: number;
    public dateShow: String;
    public aux;
    public auxDay;
    public trustedURL: SafeUrl;
    public tipoMedia: String;
    public user: any;
    public filesUpload: Array<File>;
    public url: string;
    public token: string;
    public receptorID: string;
    public patients: Array<any>;
    public patientM: string;

    constructor(private _route: ActivatedRoute, private _router: Router, private _messageService: MessagesService,
        private _sanitizer: DomSanitizer, private _userService: UserService, private _uploadService: UploadService,
        private _assignedService: AssignedService) {
        this.messages = new Messages(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '');
        this.message = new Messages(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '');
        this.listMessages = [];
        this.fecha = [];
        this.day = [];
        this.aux = moment().format('LLLL');
        this.auxDay = this.aux.split(', ');
        this.today = this.auxDay[1].split(' ');
        this.url = GLOBAL.url;
    }

    ngOnInit(): void {
        this.token = localStorage.getItem('token');
        this.identity = this._userService.getIdentity();
        if (this.identity.role === 'PATIENT_ROLE') {
            this._assignedService.getAssignPhysio(this.token).subscribe(resPhysio => {
                this.receptorID = resPhysio.myPhysio[0].physio._id;
            },
                err => {
                    console.log(err);
                });
            this.getMessages();
        } else if (this.identity.role === 'PHYSIO_ROLE') {
            this._assignedService.getAssignPatients(this.token).subscribe(resPatients => {
                this.patients = [];
                resPatients.patients.forEach(element => {
                    if (element.patient !== undefined) {
                        this.patients.push(element.patient);
                    }
                });
                console.log(this.patients);
            },
                err => {
                    console.log(err);
                });
        }
    }

    ngDoCheck(): void {
        //this.listMessages;
    }

    getMessages() {
        this._messageService.getAllMessages(this.token).subscribe(response => {
            this.listMessages = [];
            response.message.forEach(eleMessages => {
                eleMessages.orderdate = moment(moment.unix(eleMessages.orderdate)).format('L');

                if (eleMessages.emisor._id === this.receptorID || eleMessages.receptor === this.receptorID) {
                    if (eleMessages.file) {
                        this._messageService.getMediaMessage(eleMessages.file).subscribe(mediaResponse => {
                            var file = new Blob([mediaResponse], { type: 'media' });
                            var fileURL = URL.createObjectURL(file);
                            this.trustedURL = this._sanitizer.bypassSecurityTrustUrl(fileURL);
                            eleMessages.file = this.trustedURL;
                            this.listMessages.push(eleMessages);
                        },
                            err => {
                                console.log(err);
                            });
                    } else {
                        this.listMessages.push(eleMessages);
                    }
                }
                this.fecha = eleMessages.sendAt.split(', ');
                this.day = this.fecha[1].split(' ');
                this.resultado = Number(this.today[1]) - Number(this.day[1]);
                if (this.resultado === 0) {
                    this.dateShow = 'Today';
                } else if (this.resultado === 1) {
                    this.dateShow = String(this.resultado) + ' day ago';
                } else {
                    this.dateShow = String(this.resultado) + ' days ago';
                }
            });
        },
            error => {
                console.log(error)
            });
    }

    openMessage(id, status): void {
        console.log(status)
        if (status === 'unread') {
            this._messageService.updStatus(id).subscribe(response => {
                console.log(response)
            },
                err => {
                    console.log(err);
                });
        }
    }

    sendMessage(form) {
        this._messageService.sendMessage(this.token, this.receptorID, this.message).subscribe(response => {
            console.log(response);
            form.reset();
            this.getMessages();
        },
            err => {
                console.log(err);
            });
    }

    upVideo(res: any) {
        console.log(res);
        // tslint:disable-next-line:max-line-length
        this._uploadService.makeFileRequest(this.url + 'up-video/movie/' + res.movie._id, [], this.filesUpload, this.token, 'video').then((resVideo: any) => {
            console.log(resVideo);
        });
    }

    fileChangeEvent(fileInput: any) {
        this.filesUpload = <Array<File>>fileInput.target.files;
        console.log(this.filesUpload);
    }

    seeUSer(id) {
        console.log(id);
        this.patientM = id;
        // this.patientM = id.target.value;
        this.receptorID = this.patientM;
        this.getMessages();
    }
    seeMedia() {

        $('#mediaButtons').slideToggle('slow');
    }
}
