import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    providers: [UserService]
})
export class RegisterComponent implements OnInit {
    public title: string;
    public user: User;
    public passMess: String;
    public status: String;
    public errorDetails: String;

    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService) {
        this.title = 'Sign Up';
        this.user = new User('',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'ROLE_USER',
            '',
            '');
    }

    ngOnInit(): void {
        console.log('Componente de registro cargado');
    }

    onSubmit(form) {
        this._userService.register(this.user).subscribe(
            response => {
                if (response.user && response.user._id) {
                    this.status = 'Success';
                    form.reset();
                    this.errorDetails = 'Username or email not available!';
                } else {
                    this.status = 'Error';
                    if (response.message === 'Este usuario ya existe') {
                        this.errorDetails = 'Username or email not available!';
                    }
                }
            },
            err => {
                console.log(err);
            });
    }

    /* passComp(): void {
         if (document.getElementById('passwordCompare').value === document.getElementById('password').value){
             this.passMess = 'Passwords match';
         } else {
             this.passMess = 'This password dont match';
         }
     }*/
}
