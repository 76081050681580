import { Component, OnInit, DoCheck, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserService } from "../../services/user.service";
import * as moment from "moment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import * as $ from "jquery";
import { ReportService } from "../../services/report.service";
import { TestcsvComponent } from "../test-csv/testcsv.component";
import { UploadService } from "../../services/upload.service";
import * as jspdf from 'jsPDF';
import html2canvas from 'html2canvas';

@Component({
  selector: "app-report",
  styleUrls: ["./report.component.css"],
  templateUrl: "./report.component.html",
  providers: [UserService, ReportService, TestcsvComponent, UploadService]
})
export class ReportComponent implements OnInit {
  public title: string;
  public identity: any;
  public avatarUrl: any;
  public trustedUrl: any;
  public urlArr: Array<any>;
  public idReport: String;
  public trustedURL: any;
  public csvData: any;
  public file: any;
  public dataArray: Array<any>;
  public dataCSV: string;
  public condName: Array<any>;
  public names: Array<string>;
  public auxValues: Array<any>;
  public values: Array<any>;
  public cont: number;
  public donArray: Array<any>;
  public nom: string;
  public hslArray: Array<any>;
  public hsrArray: Array<any>;
  public squatArray: Array<any>;
  public stado: string;
  public allpoints: number;
  public percent: Array<any>;
  public graphs: Array<any>;
  public graphLines: any;
  public stacked: number;
  public graphLinMark: any;
  public xAxe: Array<any>;
  public auxAxe: any;
  public seeBrow: any;
  public puntos: Array<any>;
  public otroLine: any;
  public bothLine: Array<any>;

  //------------test values-------------
  public firstFrame: any;
  public secondFrame: any;
  public maxTotoal: any;
  public seeDouble: boolean;
  public seeSingle: boolean;
  public seeButtons: boolean;

  @ViewChild('report') report: ElementRef;

  // tslint:disable-next-line:max-line-length
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _sanitizer: DomSanitizer,
    private _reportService: ReportService,
    private _csvService: TestcsvComponent
  ) {
    this.title = "Reports";
    this.urlArr = this._router.url.split("/");
    this.idReport = this.urlArr[2];
    this.condName = [];
    this.cont = 0;
  }

  ngOnInit() {
    this.identity = this._userService.getIdentity();
    this.avatarUrl = this._userService.getImageAvatar();
    this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);

    this._reportService.getReport(this.idReport).subscribe(
      res => {
        console.log(res);
      },
      err => {
        this.dataCSV = err.error.text;
      }
    );
  }

  browserSee(hsl, hsr) {
    console.log(hsl.value, " --- ", hsr.value);
    if (hsl.value !== "" && hsr.value !== "") {
      this.seeButtons = true;
      this.firstFrame = hsl.value;
      this.secondFrame = hsr.value;
      this.readData();
    } else {
      console.log("entra aqui");
    }
  }

  readData() {
    this.donArray = [];
    this.names = [];
    this.dataArray = this.dataCSV.split("\n");
    this.dataArray.forEach(elArray => {
      this.condName = elArray.split(",");
      if (this.condName[0] === "name:") {
        this.nom = this.condName[1];
        this.names.push(this.condName[1]);
        this.auxValues = [];
        this.graphs = [];
        this.values = [];
        this.hslArray = [];
        this.hsrArray = [];
        this.squatArray = [];
        this.percent = [];
        this.stacked = 0;
      } else if (this.condName[0] === "raw values:") {
        this.condName.shift();
        this.condName.forEach(dat => {
          this.auxValues.push(dat);
        });
        // PRUEBA --------------------------------------------------
        this.allpoints = this.auxValues.length;
        console.log(this.firstFrame, "--", this.secondFrame);
        for (let i = this.firstFrame - 1; i < this.secondFrame - 1; i++) {
          this.values.push(this.auxValues[i]);
        }

        for (let t = 0; t < this.values.length; t++) {
          this.percent.push((t / this.values.length) * 100);
        }
        this.auxAxe = 0;
        this.xAxe = [];
        let c = 0;

        if (this.secondFrame > this.auxValues.length - 1) {
          this.maxTotoal = this.auxValues.length - 1;
        } else {
          this.graphLines = {
            data: [
              {
                x: this.percent,
                y: this.values,
                type: "scatter",
                mode: "lines",
                marker: { color: "red" }
              }
              //{ x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
            ],
            layout: { title: this.nom }
          };
          this.graphLinMark = {
            data: [
              {
                x: this.percent,
                y: this.values,
                type: "scatter",
                mode: "lines+markers",
                marker: { color: "blue" }
              }
              //{ x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
            ],
            layout: { title: this.nom }
          };
          // tslint:disable-next-line:max-line-length
          this.donArray.push({
            title: this.nom,
            valores: this.values,
            hsl: this.firstFrame,
            hsr: this.secondFrame,
            //squat: this.squatArray,
            graphLine: this.graphLines,
            graphLinMark: this.graphLinMark
          });

          // PRUEBA END -----------------------------------------
          this.cont = 0;
          // }
        }
      }
    });

    console.log(this.donArray);
    this.otro();
  }

  otro() {
    this.seeDouble = true;
    this.seeSingle = false;
    this.bothLine = [];
    for (let c = 0; c < this.donArray.length; c++) {
      if (c % 2 !== 0) {
        console.log(this.donArray[c], "---", this.donArray[c + 1]);
        this.otroLine = {
          data: [
            {
              x: this.donArray[c - 1]["graphLine"]["data"][0]["x"],
              y: this.donArray[c - 1]["graphLine"]["data"][0]["y"],
              type: this.donArray[c - 1]["graphLine"]["data"][0]["type"],
              mode: this.donArray[c - 1]["graphLine"]["data"][0]["mode"],
              marker: {
                color: "red"
              }
            },
            {
              x: this.donArray[c]["graphLine"]["data"][0]["x"],
              y: this.donArray[c]["graphLine"]["data"][0]["y"],
              type: this.donArray[c]["graphLine"]["data"][0]["type"],
              mode: this.donArray[c]["graphLine"]["data"][0]["mode"],
              marker: {
                color: "blue"
              }
            }
          ],
          layout: { title: this.donArray[c]["title"] }
        };
        this.bothLine.push(this.otroLine);
      }
    }
  }

  public downloadPDF() {
    $('.noprint').hide();
    window.print();
    $('.noprint').show();
    // let data = this.report.nativeElement;
    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options
    //   let imgWidth = 295;
    //   let pageHeight = 208;
    //   // let imgHeight = canvas.height * imgWidth / canvas.width;
    //   let imgHeight = (canvas.height * imgWidth / canvas.width);

    //   let heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png');
    //   let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF

    //   let position = 0;

    //   pdf.addImage(contentDataURL, 'PNG', 0, 25, imgWidth, imgHeight);

    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(contentDataURL, 'PNG', 0, 25, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }

    //   // console.log(contentDataURL);
    //   // pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);

    //   pdf.save('MYPdf.pdf'); // Generated PDF
    // });


    // window.print();

    /*let doc = new jsPDF();

    let specialElementHandler = {
      '#editor': function(element, renderer) {
        return true;
      }
    };

    let content = this.report.nativeElement;

    doc.fromHTML(content.innerHTML, 15, 15, {
      'width': 190,
      'elementHandlers': specialElementHandler
    });
    doc.save('report.pdf');*/
  }

}
