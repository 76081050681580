import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { User } from '../models/user';
import { GLOBAL } from './global';
import { Messages } from '../models/messages';

@Injectable()
export class MessagesService {
    public url: String;


    constructor(private _http: HttpClient) {
        this.url = GLOBAL.url;
    }

    getAllMessages(token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization', token);
        return this._http.get(this.url + 'get-all-messages', {headers: headers});
    }

    getCountMessages(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization', localStorage.getItem('token'));

        return this._http.get(this.url + 'count-messages', {headers: headers});
    }
    getMediaMessage(media): Observable<Blob> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization', localStorage.getItem('token'));

        return this._http.get(this.url + 'get-media-message/' + media, {headers: headers, responseType:'blob'});
    }

    updStatus(id): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization', localStorage.getItem('token'));

        return this._http.post(this.url + 'change-status/'+id+'/readed', {headers: headers});
    }
    sendMessage(token, id, message): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization', token);

        return this._http.post(this.url + 'new-message/' + id, message, {headers: headers});
    }
}
