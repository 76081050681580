import { Component, OnInit, DoCheck } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserService } from "../../services/user.service";
import * as moment from "moment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import * as $ from "jquery";
import { isGeneratedFile } from "@angular/compiler/src/aot/util";
import * as Calendario from '../../../scripts/calendar';
import { AssignedService } from "../../services/assigned.service";

@Component({
  selector: "app-booking",
  styleUrls: ["./booking.component.css"],
  templateUrl: "./booking.component.html",
  providers: [UserService, AssignedService]
})
export class BookingComponent implements OnInit {
  public trustedUrl: any;
  public avatarUrl: any;
  public title: String;
  public identity: any;
  public daysMonth: any;
  public myMonth: string;
  public weekends: Array<any>;
  public numWeeks: number;
  public y: number;
  public numMonth: number;
  public prevMonth: string;
  public nextMonth: string;
  public patients: Array<any>;
  patname: any;
  description: any;

  // tslint:disable-next-line:max-line-length
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _assignedService: AssignedService,
    private _sanitizer: DomSanitizer
  ) {
    this.title = "Booking";
  }

  ngOnInit() {
    // Calendario;


    // $("#myDatePicker").datepicker();
    // // Access instance of plugin
    // $("#myDatePicker").data("datepicker");

    this.identity = this._userService.getIdentity();
    this.avatarUrl = this._userService.getImageAvatar();
    this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);
    this.y = 0;


    this._assignedService.getAssignPatients(localStorage.getItem('token')).subscribe(resPatients => {
      this.patients = [];
      resPatients.patients.forEach(element => {
        if (element.patient !== undefined) {
          this.patients.push(element.patient);
        }
      });
      console.log(this.patients);
    },
      err => {
        console.log(err);
      });

  }

  ngAfterViewInit() {
  }

  calendar(mes) {
    // //this.numMonth = Number(moment().format('M'));
    // if (mes === 0) {
    //   this.numMonth = 12;
    // } else if (mes === 13) {
    //   this.numMonth = 1;
    // } else {
    //   this.numMonth = mes;
    // }

    // console.log(moment(this.numMonth).format("MMMM"));

    // this.daysMonth = moment(this.numMonth, "M").daysInMonth();
    // this.prevMonth = moment(this.numMonth, "M").format("MMMM");
    // this.myMonth = moment(this.numMonth, "M").format("MMMM");
    // this.nextMonth = moment(this.numMonth, "M").format("MMMM");
    // this.numWeeks = this.daysMonth / 7;
    // this.weekends = [];
    // for (let i = 1; i <= this.numWeeks + 1; i++) {
    //   this.weekends[i] = [];
    //   let aux = 7 * i - 6;
    //   for (let z = aux; z <= 7 * i; z++) {
    //     if (z <= this.daysMonth) {
    //       this.weekends[i].push(z);
    //     }
    //   }
    //   console.log(this.weekends[5]);
    // }
  }
  scopename(name) {
    this.patname = name.target.value;
  }
  scopedescrip(description) {
    this.description = description.target.value;
  }
}
