import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../models/user';
import { UserLogin } from '../../models/userLogin';
import { UserService } from '../../services/user.service';
import { MessagesService } from '../../services/messages.service';
import { Messages } from '../../models/messages';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [UserService, MessagesService]
})
export class LoginComponent implements OnInit {
    public title: string;
    public user: User;
    public userLogin: UserLogin;
    public status: String;
    public errorDetails: String;
    public token;
    public avatar;
    public messages: Messages;

    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService,
        private _messageService: MessagesService) {
        this.title = 'Login';
        this.user = new User('',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'ROLE_USER',
            '',
            '');
        this.userLogin = new UserLogin('', '', '', '');
        this.messages = new Messages(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '');
    }

    ngOnInit(): void {
        console.log('Componente de login cargado');

        if (this._userService.getIdentity()) {
            this._router.navigate(['/home']);
        }
    }

    onSubmit(form) {
        this._userService.login(this.userLogin).subscribe(response => {
            this.user = response.user;

            if (!response.user || !response.user._id) {
                this.status = 'error';
                this.errorDetails = 'User/email or password incorrect, try again!';
            } else {

                localStorage.setItem('user', JSON.stringify(this.user));

                this.getToken();
            }
        },
            error => {
                let errorMessage = <any>error;

                if (errorMessage != null) {
                    this.status = 'Error';
                    this.errorDetails = 'User/email or password incorrect, try again!';
                }
            });
    }

    getCounter() {
        this._userService.getCounter(localStorage.getItem('token')).subscribe(response => {
            localStorage.setItem('stats', JSON.stringify(response));
            this.status = 'Success';
            this.errorDetails = 'Login successful, enjoy!!';
            this._messageService.getCountMessages().subscribe(response => {
                localStorage.setItem('messageCount', response.count);

                if (JSON.parse(localStorage.getItem('user')).role === 'PHYSIO_ROLE') {
                    this._router.navigate(['/reportClients']);
                } else if (JSON.parse(localStorage.getItem('user')).role === 'PATIENT_ROLE') {
                    this._router.navigate(['/home']);
                }
            },
                err => {
                    console.log(err);
                });
        },
            error => {
                console.log(error);
            });
    }

    getToken() {
        this._userService.login(this.userLogin, 'true').subscribe(response => {
            this.token = response.token;

            if (this.token.length <= 0) {
                this.status = 'error';
                this.errorDetails = 'Error al generar el token';

            } else {
                localStorage.setItem('token', this.token);

                this._userService.getAvatar(this.token, this.user.image).subscribe(response => {
                    console.log(response);
                    var file = new Blob([response], { type: 'image/jpeg' });
                    var fileURL = URL.createObjectURL(file);
                    localStorage.setItem('avatar', fileURL);
                },
                    err => {
                        console.log(err);
                    });
                /*this.status = 'Success';
                this.errorDetails = 'Login successful, enjoy!!';

                this._router.navigate(['/home']);*/

                this.getCounter();
            }
        },
            error => {
                let errorMessage = <any>error;

                if (errorMessage != null) {
                    this.status = 'Error';
                    this.errorDetails = 'User/email or password incorrect, try again!';
                }
            });
    }
}
