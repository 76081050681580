import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { AssignedService } from '../services/assigned.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css'],
  providers: [UserService, AssignedService]
})
export class ModalsComponent implements OnInit {
  public user: User;
  constructor(private _userService: UserService, private _assignedService: AssignedService) {

    this.user = new User('',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'PATIENT_ROLE',
      '',
      '');

  }

  ngOnInit() {
  }

  onRegister(f: NgForm): void {
    console.log(this.user);
    this._userService.register(this.user).subscribe(
      response => {
        console.log(response);
        if (response.user && response.user._id) {

          f.reset();

          this._assignedService.assignPatient(response.user._id, localStorage.getItem('token')).subscribe(res => {
            console.log(res);
          },
            err => {
              console.log(err);
            });

        } else {

          if (response.message === 'Este usuario ya existe') {
            alert("User not available");
          }
        }
      },
      err => {
        console.log(err);
      });
  }

}
