import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PlotlyModule } from 'angular-plotly.js';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { routing, appRoutingProviders } from './app.routing';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BookingComponent } from './components/booking/booking.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { MyphysioComponent } from './components/myphisio/myphysio.component';
import { RoutinesComponent } from './components/routines/routines.component';
import { ReportClientsComponent } from './components/reports-clients/reportClients.component';
import { MypatientsComponent } from './components/mypatients/mypatients.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TestcsvComponent } from './components/test-csv/testcsv.component';
import { EditroutineComponent } from './components/edit-routine/editroutine.component';
import { ReportComponent } from './components/report/report.component';
import { ModalsComponent } from './modals/modals.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    MessagesComponent,
    ProfileComponent,
    BookingComponent,
    TimelineComponent,
    MyphysioComponent,
    RoutinesComponent,
    ReportClientsComponent,
    MypatientsComponent,
    TestcsvComponent,
    EditroutineComponent,
    ReportComponent,
    ModalsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    PlotlyModule,
    FormsModule,
    AngularFontAwesomeModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
