import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import { AssignedService } from '../../services/assigned.service';

@Component({
    selector: 'app-mypatients',
    styleUrls: ['./mypatients.component.css'],
    templateUrl: './mypatients.component.html',
    providers: [UserService, AssignedService]
})

export class MypatientsComponent implements OnInit {
    public title: string;
    public identity: any;
    public avatarUrl: any;
    public trustedUrl: any;
    public patientsArray: Array<any>;
    public countPatient: number;
    public prePatient: Array<any>;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _assignService: AssignedService, private _userService: UserService, private _sanitizer: DomSanitizer) {
        this.title = 'My Patients';
        this.countPatient = 0;
    }

    ngOnInit() {
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);

        this.patientsArray = [];

        this._assignService.getAssignPatients(localStorage.getItem('token')).subscribe(res => {
            res.patients.forEach(elPat => {

                console.log(elPat.patient);

                if (elPat.patient !== undefined) {
                    this.patientsArray.push(elPat);
                }

            });
            console.log('Aqui', this.patientsArray);
        },
            err => {
                console.log(err);
            });
    }
}
