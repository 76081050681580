import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service'; 
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    styleUrls: ['./profile.component.css'],
    templateUrl: './profile.component.html',
    providers: [UserService]
})

export class ProfileComponent implements OnInit {
    public trustedUrl: any;
    public avatarUrl: any;
    public title: String;
    public identity: any;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _sanitizer: DomSanitizer) {
        this.title = 'Profile';
    }

    ngOnInit(){
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);    }
}
