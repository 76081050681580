import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

@Component({
    selector: 'app-editroutine',
    styleUrls: ['./editroutine.component.css'],
    templateUrl: './editroutine.component.html',
    providers: [UserService]
})

export class EditroutineComponent implements OnInit {
    public title: string;
    public identity: any;
    public avatarUrl: any;
    public trustedUrl: any;
    public filesUpload: Array<File>;
    imagencita: void;
    url: any;

    // tslint:disable-next-line:max-line-length
    constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService, private _sanitizer: DomSanitizer) {
        this.title = 'Edit Routine';
    }

    ngOnInit() {
        this.identity = this._userService.getIdentity();
        this.avatarUrl = this._userService.getImageAvatar();
        this.trustedUrl = this._sanitizer.bypassSecurityTrustUrl(this.avatarUrl);
    }

    fileChangeEvent(fileInput: any) {

        if (fileInput.target.files && fileInput.target.files[0]) {
            let reader = new FileReader();
        
            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (fileInput: ProgressEvent) => {
              this.url = (<FileReader>fileInput.target).result;
            };
        
            reader.readAsDataURL(fileInput.target.files[0]);
          }
          console.log(this.url);

        /*this.filesUpload =  <Array<File>>fileInput.target.files;
        let file = new Blob([fileInput], {type: 'img'});
        let fileURL = URL.createObjectURL(file);

        console.log(file);
        console.log(fileURL);

        let reader = new FileReader();
        this.imagencita = reader.readAsDataURL(file);
        console.log(this.imagencita);
        console.log(this.filesUpload[0]);*/
    }

}
