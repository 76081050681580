import { User } from './user';

export class Messages {
    constructor (
        public _id: string,
        public emisor: String,
        public receptor: String,
        public textMessage: String,
        public file: String,
        public status: String,
        public tipo: String,
        public sendAt: String
    ) {}
}
